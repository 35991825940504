import axios, { AxiosInstance, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import i18next from 'i18next'
import { AUTH } from '@/api/endpoints'
import { APP_CONFIG } from '@/config/app.config'

// Define expected shape of refresh response
interface TokenRefreshResponse {
  access: string
  refresh?: string
}

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean
}

const { STORAGE_KEYS } = APP_CONFIG.AUTH
const { TIMEOUT, BASE_URL } = APP_CONFIG.API

// Safely get CSRF token from cookie
const getCSRFToken = (): string | null => {
  const match = /csrftoken=([^;]+)/.exec(document.cookie)
  return match ? match[1] : null
}

/**
 * Creates an axios client with custom configuration and interceptors
 * @param initialToken Optional initial authentication token
 * @returns Configured Axios instance
 */
const axiosClient = (initialToken: string | null = null): AxiosInstance => {
  const getBaseURL = (): string => {
    const locale = i18next.language || 'en'
    return `${BASE_URL}/${locale}/api/`
  }

  const REFRESH_URL = `${BASE_URL}/${i18next.language}/api/auth/token/refresh/`

  const initialHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(initialToken ? { Authorization: `Bearer ${initialToken}` } : {})
  }

  const client = axios.create({
    baseURL: getBaseURL(),
    headers: initialHeaders,
    timeout: TIMEOUT,
    withCredentials: true
  })

  client.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    const csrfToken = getCSRFToken()
    if (csrfToken && config.method && ['post', 'put', 'patch', 'delete'].includes(config.method)) {
      config.headers['X-CSRFToken'] = csrfToken
    }

    if (config.url && (config.url.includes(AUTH.SIGN_IN) || config.url.includes(AUTH.SIGN_UP))) {
      return config
    }

    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  })

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const isDebugMode =
        import.meta.env.VITE_DEBUG === 'true' || import.meta.env.MODE === 'development'

      // Handle network errors
      if (!error.response) {
        if (isDebugMode) {
          // eslint-disable-next-line no-console
          console.error('Network/Server error:', error.message)
        }
        const translatedError = i18next.t('networkError', { ns: 'common' })
        return Promise.reject(new Error(translatedError))
      }

      const originalRequest = error.config as CustomAxiosRequestConfig
      const { response } = error

      // Handle token refresh for 401 errors
      if (response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post<TokenRefreshResponse>(
              REFRESH_URL,
              { refresh: refreshToken },
              {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
              }
            )

            const { access: newAccessToken, refresh: newRefreshToken } = refreshResponse.data

            localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, newAccessToken)
            if (newRefreshToken) {
              localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, newRefreshToken)
            }

            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
            return await client.request(originalRequest)
          } catch (refreshError) {
            if (isDebugMode) {
              // eslint-disable-next-line no-console
              console.error('Token refresh failed:', refreshError)
            }
            localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
            localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
          }
        }
      }

      // Log debug information
      if (isDebugMode) {
        // eslint-disable-next-line no-console
        console.log('Response Error', response.status, response.data)
      }

      // Clear tokens on unauthorized access
      if (response.status === 401) {
        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
      }

      const translatedMessage = i18next.t('somethingWentWrong', { ns: 'common' }) || error.message
      return Promise.reject(new Error(translatedMessage))
    }
  )

  return client
}

export default axiosClient
