import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { fetchNotifications } from '@/modules/core/services/notifications.services'
import { ApiNotification } from '@/modules/core/types/notification.types'

/**
 * Interface defining the shape of the Notification Context
 */
interface NotificationContextType {
  /**
   * The current count of unread notifications
   */
  unreadCount: number

  /**
   * Function to refresh the unread notification count
   * @returns A promise that resolves when the count has been updated
   */
  refreshUnreadCount: () => Promise<void>
}

// Create the context with a default value
const NotificationContext = createContext<NotificationContextType>({
  unreadCount: 0,
  // Provide a no-op implementation that returns a resolved promise
  refreshUnreadCount: async () => Promise.resolve()
})

/**
 * Custom hook to access the notification context
 * @returns The notification context value
 */
export const useNotifications = (): NotificationContextType => useContext(NotificationContext)

/**
 * Props for the NotificationProvider component
 */
interface NotificationProviderProps {
  /**
   * Child components that will have access to the notification context
   */
  children: ReactNode

  /**
   * User role used to filter notifications by relevance
   */
  userRole: string | null
}

/**
 * Provider component that maintains and updates the unread notification count
 * @param props - The component props
 * @returns A provider component that makes notification data available to its children
 */
export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
  userRole
}) => {
  const [unreadCount, setUnreadCount] = useState<number>(0)

  /**
   * Fetches notifications and updates the unread count
   * @returns A promise that resolves when the count has been updated
   */
  const refreshUnreadCount = async (): Promise<void> => {
    try {
      const response = await fetchNotifications()

      if (Array.isArray(response.results)) {
        // First, let's just count all unread notifications regardless of type or role
        // This is useful for debugging to see the total number of unread notifications
        const totalUnreadCount = response.results.filter((note) => !note.is_read).length
        console.log('Total unread notifications in system:', totalUnreadCount)

        // Now let's apply appropriate filtering (or don't filter at all)
        // This temporary change allows us to see all notifications for debugging
        const allNotifications: ApiNotification[] = response.results

        // Directly set the count to total unread count for now
        // This is a temporary change to help debug the issue
        setUnreadCount(totalUnreadCount)

        console.log({
          userRole,
          totalNotifications: allNotifications.length,
          unreadCount: totalUnreadCount,
          allNotifications: allNotifications.map((n) => ({
            id: n.id,
            type: n.notification_type,
            isRead: n.is_read,
            message: n.message
          }))
        })
      }
    } catch (error) {
      console.error('Error fetching unread notification count:', error)
    }
  }

  // Initial fetch when component mounts
  useEffect(() => {
    // Explicitly mark promise with void operator
    void refreshUnreadCount()

    // Create a non-async wrapper for setInterval
    const refreshWrapper = () => {
      void refreshUnreadCount()
    }

    // Set up periodic refresh (e.g., every 5 minutes)
    const intervalId = setInterval(refreshWrapper, 5 * 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [userRole])

  return (
    <NotificationContext.Provider value={{ unreadCount, refreshUnreadCount }}>
      {children}
    </NotificationContext.Provider>
  )
}
