import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import type { MenuProps } from 'antd'
import sidebarImage from '@/assets/images/Coordinating-Council.svg'
import { useNavigateTo } from '@/hooks/useNavigateTo'
import { CSSProperties } from 'react'
import { useNotifications } from './NotificationContext'

import {
  CustomMenuItem,
  getAdminMenuItems,
  getBusinessUserMenuItems,
  getGuestMenuItems
} from './MenuItems'

const { Sider } = Layout

interface SidebarProps {
  isMobile: boolean
  collapsed: boolean
  onCollapse: (c: boolean) => void
  userRole: string | null
}

const siderStyle = (isMobile: boolean, collapsed: boolean, isRtl: boolean): CSSProperties => ({
  overflow: 'auto',
  height: '100vh',
  position: isMobile ? 'fixed' : 'sticky',
  top: 0,
  bottom: 0,
  left: isMobile && !isRtl ? (collapsed ? '-300px' : '0') : 'auto',
  right: isMobile && isRtl ? (collapsed ? '-300px' : '0') : 'auto',
  width: isMobile ? '300px !important' : '350px !important',
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  transform:
    isMobile && collapsed ? (isRtl ? 'translateX(100%)' : 'translateX(-100%)') : 'translateX(0)',
  transition: 'transform 0.3s ease-in-out',
  zIndex: isMobile ? 1000 : 'auto',
  minHeight: '100vh'
})

const logoStyle: CSSProperties = {
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '0.01rem solid #f0f0f0',
  padding: '0 16px',
  backgroundColor: '#FFFFFF'
}

const menuStyle: CSSProperties = {
  flex: 1,
  borderRight: 0,
  padding: '16px 8px',
  paddingBottom: '80px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  fontSize: '14px'
}

/**
 * Sidebar component for role-based navigation.
 *
 * @param isMobile - Whether the screen is mobile-sized
 * @param collapsed - Whether the sidebar is collapsed
 * @param onCollapse - Callback to toggle sidebar collapse
 * @param userRole - The current user's role used to filter menu items
 * @returns A responsive sidebar layout component containing the app logo, role-based navigation menu, and user footer section
 */
export default function Sidebar({ isMobile, collapsed, onCollapse, userRole }: SidebarProps) {
  const location = useLocation()
  const navigateTo = useNavigateTo()
  const { t, i18n } = useTranslation('core')
  const isRtl = i18n.dir() === 'rtl'
  const { unreadCount } = useNotifications()

  let rawItems: CustomMenuItem[] = []
  switch (userRole) {
    case 'SYSTEM_OWNER':
      rawItems = getAdminMenuItems(t)
      break
    case 'BUSINESS_USER':
      rawItems = getBusinessUserMenuItems(t)
      break
    case 'VIEWER_USER':
    case 'SYSTEM_USER':
    default:
      rawItems = getGuestMenuItems(t)
      break
  }

  const currentPath = location.pathname.split('/').slice(2).join('/')
  const match = rawItems.find((item) => {
    if (item.path === '') return currentPath === '' || currentPath === '/'
    return currentPath.startsWith(item.path)
  })

  const selectedKey = (match?.key ?? rawItems[0]?.key) || '1'

  const getMenuItemIcon = (item: CustomMenuItem, key: string) => {
    // Pass unreadCount to notification icon for item with key '6' (notifications)
    if (item.key === '6') {
      // The notification menu item key
      // Type assertion to ensure TypeScript knows we're calling a function with 2 parameters
      console.log('Unread notification count in sidebar:', unreadCount) // Debug log
      return (item.icon as (selectedKey: string, badgeCount?: number) => React.ReactNode)(
        key,
        unreadCount
      )
    }
    return item.icon(key)
  }

  const menuItems: MenuProps['items'] = rawItems.map((item) => ({
    key: item.key,
    icon: getMenuItemIcon(item, selectedKey),
    label: item.label,
    onClick: () => {
      navigateTo(item.path || '')
    }
  }))

  return (
    <>
      <Sider width={isMobile ? 250 : 320} style={siderStyle(isMobile, collapsed, isRtl)}>
        <div style={logoStyle}>
          <img src={sidebarImage} alt="logo" className="h-16" />
        </div>

        <div style={{ flex: 1, overflowY: 'auto', position: 'relative' }}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[selectedKey]}
            style={menuStyle}
            items={menuItems}
            className="custom-sidebar-menu"
          />
        </div>
      </Sider>

      {isMobile && !collapsed && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            onCollapse(true)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onCollapse(true)
            }
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 998
          }}
        />
      )}
    </>
  )
}
