import axiosClient from '@/api/axiosInstance'
import { SEASON } from '@/api/endpoints'
import axios, { AxiosResponse } from 'axios'
import {
  Season,
  FetchSeasonResponse,
  addSeasonRespose,
  AssignSeason,
  SeasonDetail,
  AddBatch
} from '../types/season.types'

/**
 * Fetches a list of seasons from the system.
 * @returns A list of seasons.
 */
export const fetchSeason = async (): Promise<Season[]> => {
  const api = axiosClient()
  try {
    const response: AxiosResponse<FetchSeasonResponse> = await api.get<FetchSeasonResponse>(
      SEASON.seasonList
    )
    // Verify that the fetched data is an array before using map on it
    if (Array.isArray(response.data.results)) {
      return response.data.results
    } else {
      // If no services exist or data is not an array, return an empty array
      return []
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      // Return an empty array if no services are found (404)
      return []
    }
    throw new Error('Failed to fetch season data')
  }
}

/**
 * Adds a new season to the system.
 * @param payload - The season data to be added.
 * @returns The added season data.
 */
export const addSeason = async (payload: Season): Promise<addSeasonRespose> => {
  try {
    const api = axiosClient()
    const response = await api.post<addSeasonRespose>(SEASON.addSeason, payload)
    return response.data
  } catch {
    throw new Error('Failed to add new seaon please try again later')
  }
}

/**
 * Adds a new season to the system.
 * @param payload - The season data to be added.
 * @returns The added season data.
 */
export const assignSeason = async (payload: AssignSeason): Promise<addSeasonRespose> => {
  try {
    const api = axiosClient()
    const response = await api.post<addSeasonRespose>(SEASON.assignSeason, payload)
    return response.data
  } catch {
    throw new Error('This business model already has an assigned season.')
  }
}

/**
 * Fetches a list of seasons from the system.
 * @returns A list of seasons.
 */
export const getSeasonList = async (): Promise<SeasonDetail[]> => {
  const api = axiosClient()
  try {
    const response: AxiosResponse<SeasonDetail> = await api.get<SeasonDetail>(SEASON.getSeasonList)
    // Verify that the fetched data is an array before using map on it
    if (Array.isArray(response.data)) {
      return response.data
    } else {
      // If no services exist or data is not an array, return an empty array
      return []
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      // Return an empty array if no services are found (404)
      return []
    }
    throw new Error('Failed to fetch season data')
  }
}
/**
 * Fetches the company details information.
 * @param {string} id - The user ID for which to fetch the profile
 * @returns {Promise<AxiosResponse<SeasonDetail>>} The user profile data.
 */
export const getSeasonDetails = async (id: string): Promise<AxiosResponse<SeasonDetail>> => {
  const api = axiosClient()
  const response = await api.get(SEASON.getSeasonDetails(id))
  return response
}

/**
 * Updates an existing season with new batch data.
 * @param id - The ID of the season to update.
 * @param payload - The batch data to be updated.
 * @returns The updated season data.
 */
export const putSeasonDetails = async (
  id: string,
  payload: AddBatch
): Promise<AxiosResponse<SeasonDetail>> => {
  const api = axiosClient()

  const response = await api.put(SEASON.getSeasonDetails(id), payload)
  return response
}
