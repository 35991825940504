import axiosClient from '@/api/axiosInstance'
import {
  ApiNotification,
  NotificationsResponse,
  NotificationType,
  TableNotification,
  NotificationTypeEnum
} from '@/modules/core/types/notification.types'

import { NOTIFICATION } from '@/api/endpoints'

// Get API client instance
const apiClient = axiosClient()

/**
 * Formats a date string to Gregorian format
 * @param {string} dateString - ISO date string from API
 * @returns {string} Formatted Gregorian date string (MM/DD/YYYY)
 */
const formatGregorianDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

/**
 * Formats a date string to Hijri format
 * @returns {string} Formatted Hijri date string
 */
const formatHijriDate = (): string => {
  // Placeholder for Hijri date conversion
  return 'Hijri date placeholder'
}

/**
 * Fetches all notifications from the API
 * @returns {Promise<NotificationsResponse>} Promise containing the notifications data with pagination
 */
export const fetchNotifications = async (): Promise<NotificationsResponse> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<NotificationsResponse>(NOTIFICATION.getNotifications)

    // Check if response is not JSON (e.g., HTML response from auth redirect)
    if (typeof response.data === 'string' || typeof response.data !== 'object') {
      console.error('Invalid API response format:', response.data)
      // Return an empty valid response structure to prevent errors
      return {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }

    // Ensure that results always exists, even if the API doesn't return it
    const responseData = response.data
    if (!Array.isArray(responseData.results)) {
      console.warn('API response missing results array, using empty array instead')
      return {
        ...responseData,
        results: []
      }
    }

    return responseData
  } catch (error) {
    console.error('Error fetching notifications:', error)
    // Return an empty valid response structure instead of throwing
    return {
      count: 0,
      next: null,
      previous: null,
      results: []
    }
  }
}

/**
 * Fetches details for a specific notification by ID
 * Note: This API call will automatically mark the notification as read
 * @param {string} id - Notification ID
 * @returns {Promise<ApiNotification>} Promise containing the notification details
 */
export const fetchNotificationDetails = async (id: string): Promise<ApiNotification> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<ApiNotification>(NOTIFICATION.getNotificationDetails(id))

    // Check if response is not a valid notification object
    if (typeof response.data === 'string' || !response.data.id) {
      console.error(`Invalid notification details response for ID ${id}:`, response.data)
      throw new Error('Invalid notification response')
    }

    return response.data
  } catch (error) {
    console.error(`Error fetching notification details for ID ${id}:`, error)
    throw error
  }
}

/**
 * Fetches all available notification types
 * @returns {Promise<NotificationType[]>} Promise containing array of notification types
 */
export const fetchNotificationTypes = async (): Promise<NotificationType[]> => {
  try {
    // Use apiClient instead of axios
    const response = await apiClient.get<NotificationType[]>(NOTIFICATION.getNotificationsTypes)

    // Check if response is not a valid array
    if (!Array.isArray(response.data)) {
      console.error('Invalid notification types response:', response.data)
      // Return an empty array instead of throwing
      return []
    }

    return response.data
  } catch (error) {
    console.error('Error fetching notification types:', error)
    // Return an empty array instead of throwing
    return []
  }
}

/**
 * Transforms API notification objects to the format needed for table display
 * @param {ApiNotification[]} apiNotifications - Raw notifications from the API
 * @param {NotificationType[]} [notificationTypes] - Optional list of notification types
 * @returns {TableNotification[]} Transformed notifications for table display
 */
export const transformToTableNotifications = (
  apiNotifications: ApiNotification[],
  notificationTypes: NotificationType[] = []
): TableNotification[] => {
  return apiNotifications.map((notification) => ({
    ...notification,
    dateHijri: formatHijriDate(),
    dateGregorian: formatGregorianDate(notification.created_at),
    ...(notificationTypes.length > 0 ? { notificationType: notificationTypes } : {})
  }))
}

/**
 * Gets the notification type enum value from a type ID
 * @param {number} typeId - Notification type ID
 * @param {NotificationType[]} types - Available notification types
 * @returns {NotificationTypeEnum | undefined} The corresponding notification type enum or undefined if not found
 */
export const getNotificationTypeFromId = (
  typeId: number,
  types: NotificationType[]
): NotificationTypeEnum | undefined => {
  // First check if types is defined and not empty
  if (types.length === 0) {
    return undefined
  }

  const typeInfo = types.find((type) => type.id === typeId)

  if (!typeInfo) {
    return undefined
  }

  // Map the API type_name to the enum
  switch (typeInfo.type_name) {
    case 'TASKS_ASSIGNED':
      return NotificationTypeEnum.TASKS_ASSIGNED
    case 'SEASON_UPDATED':
      return NotificationTypeEnum.SEASON_UPDATED
    case 'SEASON_APPROVAL':
      return NotificationTypeEnum.SEASON_APPROVAL
    case 'TASK_DONE':
      return NotificationTypeEnum.TASK_DONE
    case 'TASK_APPROVAL':
      return NotificationTypeEnum.TASK_APPROVAL
    default:
      return undefined
  }
}
